import React from "react";
import * as Yup from 'yup';
import { TrashIcon } from "./trash";
import { Container } from './styled';
import { useRouter } from "next/router";
import { Grid, Pagination } from '@mui/material';
import { LoadingBuffer } from "../utils/Loading";
import { yupResolver } from "@hookform/resolvers/yup";
import { DeleteElaborationDialog } from "../../Modal/Delete";
import { TextFieldElaboration } from "../../Inputs/TextField";
import { useTipeOfFlow } from "@/services/zustand/tipo_fluxo";
import { ActionButtonElaborations } from "../../ActionButton";
import { Organograma } from "@/utils/organograma/Organograma";
import { SelectFieldElaboration } from "../../Inputs/SelectField";
import { useHierarchyPreparationOfPlans, usePreparationOfPlans } from "@/hooks/elaboracao_planos";
import { ElaborationService } from "@/services/endpoints/elaboracao";
import { useSnackbarElaboration } from "@/hooks/useSnackbarElaboration";
import { SubmitHandler, useFieldArray, useForm } from "react-hook-form";
import { ReutilizarElaborationDialog } from "../Vision/ReutilizarModal";
import { MultipleSelect } from "@/components/global/Inputs/MultipleInput";
import { addItemToNodeOrganogram } from "@/utils/organograma/addItemToNode";
import { findingMenuItemKey } from "@/contexts/elaboracao_planos/constants";
import { DisclaimerReuseElaborationDialog } from "../Vision/disclaimerModal";
import { DashboardManagementPlansService } from "@/services/endpoints/dashboard";
import { useElaborationCardItem } from "@/services/zustand/elaboration_card_item";
import { analisarTerritorioArray, encontrarCorrespondentes, handleContentRelatedToTerritorySelected, removeDuplicatesFromA } from "../utils";
import { RelacionamentoJsonService } from "@/services/endpoints/hierarquia";

const defaultValue = {
  id: '',
  tor: [],
  setor: '',
  orgao: '',
  prazo: null,
  texto: '',
  territorio: '',
  responsavel: '',
  publicoAlvo: '',
}

type InputsInitiatives = {
  iniciativas: any;
}

const SchemaInitiatives = Yup.object().shape({
  iniciativas: Yup.array().of(
    Yup.object().shape({
      setor: Yup.string(),
      orgao: Yup.string(),
      responsavel: Yup.string(),
      texto: Yup.string().required('Campo obrigatório'),
      territorio: Yup.string().required('Campo obrigatório'),
      publicoAlvo: Yup.string().required('Campo obrigatório')
    })
  ).required(),
});

const transformInitiativesData = (data: any[]) => {
  return data.map(item => {
    const tor = item?.iniciativasTerritorios.map((m: any) => {
      if (m?.bairro) return { id: m?.bairro?.id, descricao: m?.bairro?.name };
      if (m?.regiaoAdministrativa) return { id: m?.regiaoAdministrativa?.id, descricao: m?.regiaoAdministrativa?.name };
      if (m?.areaPlanejamento) return { id: m?.areaPlanejamento?.id, descricao: m?.areaPlanejamento?.name };
      if (m?.regiaoPlanejamento) return { id: m?.regiaoPlanejamento?.id, descricao: m?.regiaoPlanejamento?.name };
      return null;
    }).filter(Boolean);

    const territorio = analisarTerritorioArray(item.iniciativasTerritorios)

    return {
      tor,
      territorio,
      id: item?.id,
      texto: item.texto || '',
      orgao: item.orgao?.id || '',
      setor: item.setor?.id || '',
      publicoAlvo: item.publicoAlvo || '',
      responsavel: item.responsavel || '',
      // prazo: item.data ? dayjs(item.data) : null,
    };
  });
};

const transformInitiativesDataReuse = (data: any[]) => {
  return data.map(item => {
    const tor = item?.iniciativasTerritorios.map((m: any) => {
      if (m?.bairro) return { id: m?.bairro?.id, descricao: m?.bairro?.name };
      if (m?.regiaoAdministrativa) return { id: m?.regiaoAdministrativa?.id, descricao: m?.regiaoAdministrativa?.name };
      if (m?.areaPlanejamento) return { id: m?.areaPlanejamento?.id, descricao: m?.areaPlanejamento?.name };
      if (m?.regiaoPlanejamento) return { id: m?.regiaoPlanejamento?.id, descricao: m?.regiaoPlanejamento?.name };
      return null;
    }).filter(Boolean);

    const territorio = analisarTerritorioArray(item.iniciativasTerritorios)

    return {
      tor,
      territorio,
      texto: item.texto || '',
      orgao: item.orgao?.id || '',
      setor: item.setor?.id || '',
      publicoAlvo: item.publicoAlvo || '',
      responsavel: item.responsavel || '',
      // prazo: item.data ? dayjs(item.data) : null,
    };
  });
};

export const InitiativesForm: React.FC = () => {
  // const {
  //   page,
  //   label,
  //   setPage,
  //   iniciativas,
  //   eixos_tematicos,
  //   currentIndexEixo,
  //   watchInitiatives,
  //   errosInitiatives,
  //   updateInitiatives,
  //   removeInitiatives,
  //   appendInitiatives,
  //   controlInitiatives,
  //   setValueInitiatives,
  //   registerInitiatives,
  //   setCurrentIndexEixo,
  //   handleSubmitInitiatives,
  //   transformInitiativesDataReuse
  // } = useInitiavitesForm();

  const {
    trigger,
    clearErrors,
    watch: watchInitiatives,
    control: controlInitiatives,
    register: registerInitiatives,
    setValue: setValueInitiatives,
    handleSubmit: handleSubmitInitiatives,
    formState: { errors: errosInitiatives } } = useForm<InputsInitiatives>({
      resolver: yupResolver(SchemaInitiatives),
      defaultValues: { iniciativas: [defaultValue] }
    });

  const { append: appendInitiatives, update: updateInitiatives, remove: removeInitiatives } = useFieldArray({
    control: controlInitiatives,
    name: "iniciativas"
  });

  const fields = watchInitiatives("iniciativas");

  const {
    InitiativesElaboration,
    GetInitiativesElaborations,
    DeleteInitiativesElaborations,
    UpdateInitiativesElaborations,
  } = new ElaborationService();

  const router = useRouter();
  const [page, setPage] = React.useState(1);
  const [label, setLabel] = React.useState('');
  const { completCode } = usePreparationOfPlans();
  const [data, setData] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [filterData, setFilterData] = React.useState({
    setor: [] as any[],
    orgaoResponsavel: [] as any[],
    territorio: {} as Record<string, any>
  });
  const { tipo: tipoFluxo, planId } = useTipeOfFlow();
  const [deleteIndex, setDeleteIndex] = React.useState(0)
  const [deleteId, setDeleteId] = React.useState(0)
  const [reutilizar, setReutilizar] = React.useState(false);
  const [preview, setPreview] = React.useState<boolean>(false);
  const { getAllFilters } = new DashboardManagementPlansService();
  const [iniciativas, setIniciativas] = React.useState<any[]>([]);
  const [currentIndexEixo, setCurrentIndexEixo] = React.useState(0);
  const [openDisclaimer, setOpenDisclaimer] = React.useState(false);
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const [eixos_tematicos, setEixosTematicos] = React.useState<any[]>([]);
  const { toggleErrorSnackbar, toggleSuccessSnackbar } = useSnackbarElaboration()
  const zustandSelectedCardItem = useElaborationCardItem(state => state.cardItem);
  const { GetThematicAxisElaboration, GetMacroactionsEixoElaborations, GetGoalsEixoElaborations } = new ElaborationService();
  const { itemsMenu, selectedItemsMenu, handleFetchProgressCodePlanId, setDefaultItemMenuId, setNavigable } = usePreparationOfPlans();
  const [disabeSubmit, setDisableSubmit] = React.useState(false);
  const total = iniciativas?.length;

  const handleExternalSubmit = async () => {
    const isValid = await trigger();

    if (isValid) {
      handleSubmitInitiatives(onSubmit)({
        data: fields,
        nativeEvent: { submitter: { name: 'any' } }
      } as any);
    }
  };

  const handleChange = async (_: any, value: any) => {
    if (typeof _ == 'string') {
      setPage(value);
      return;
    }

    const isValid = await trigger();

    if (isValid) {
      handleSubmitInitiatives(onSubmit)({
        data: fields,
        nativeEvent: { submitter: { name: 'any' } }
      } as any);
    }
    setPage(value);
  };

  const filterSectors = (data: any, orgao: any) => {
    const findItem = data?.orgaoResponsavel?.find((item: any) => item?.id == orgao);
    const filterItems =
      data?.setor?.filter((item: any) => item.codUa == findItem?.codUa)
        .map((item: any) => ({ value: item?.id, name: item?.descricao }))

    return filterItems
  }

  const handleLeftClick = () => {
    handleExternalSubmit()
    setCurrentIndexEixo(currentIndexEixo === 0 ? eixos_tematicos.length - 1 : currentIndexEixo - 1);
  }

  const handleRightClick = () => {
    handleExternalSubmit()
    setCurrentIndexEixo(currentIndexEixo === eixos_tematicos.length - 1 ? 0 : currentIndexEixo + 1);
  }

  function getMessageErrorByNameRef(json: any, title: any) {
    const foundObject = json?.iniciativas?.find((item: any) => item[title]);
    return foundObject ? foundObject[title].message : null;
  };

  const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

  React.useEffect(() => {
    if (router.query.id && router.pathname.includes('elaboracao_planos') || router.pathname.includes('aprovacao_planos')) {
      GetThematicAxisElaboration(router.query.id).then(res => {
        setEixosTematicos(res.data);
      });
    }
  }, [router.query, router.pathname, completCode]);

  React.useEffect(() => {
    if ((router.query.id && router.pathname.includes('elaboracao_planos') && eixos_tematicos[currentIndexEixo]?.id) || (router.pathname.includes('aprovacao_planos') && eixos_tematicos[currentIndexEixo]?.id)) {
      setLoading(true);
      const metas = completCode.find(item => item.code == 'metas' && item.skipped == false);
      const macroacoes = completCode.find(item => item.code == 'macroacoes' && item.skipped == false);

      if (macroacoes != undefined && metas != undefined) {
        GetMacroactionsEixoElaborations(router.query.id, eixos_tematicos[currentIndexEixo]?.id).then(res => {
          setIniciativas(res.data);
          setLabel('Macroação')
        }).finally(() => setLoading(false));
      }

      if (macroacoes != undefined && metas == undefined) {
        GetMacroactionsEixoElaborations(router.query.id, eixos_tematicos[currentIndexEixo]?.id).then(res => {
          setIniciativas(res.data);
          setLabel('Macroação')
        }).finally(() => setLoading(false));;
      }

      if (macroacoes == undefined && metas != undefined) {
        GetGoalsEixoElaborations(router.query.id, eixos_tematicos[currentIndexEixo]?.id).then(res => {
          setIniciativas(res.data);
          setLabel('Meta')
        }).finally(() => setLoading(false));;
      }
    }
  }, [completCode, router.pathname, router.query, eixos_tematicos, currentIndexEixo]);

  React.useEffect(() => {
    const data = iniciativas[page - 1];
    clearErrors()
    if (data?.iniciativas?.length > 0) {
      setValueInitiatives('iniciativas', transformInitiativesData(data.iniciativas))
    } else {
      setValueInitiatives('iniciativas', [defaultValue])
    }
  }, [iniciativas, page, completCode]);

  React.useEffect(() => {
    if (router.pathname.split('/').includes('elaboracao_planos') || router.pathname.split('/').includes('aprovacao_planos')) {
      getAllFilters().then((res: any) => {
        const { data } = res.data as any;
        setFilterData(data);
      })
    }
  }, [router.pathname]);

  React.useEffect(() => {
    if (!router.isReady) return

    setPreview(router.asPath.includes('preview'));
  }, [router]);

  React.useEffect(() => {
    if (planId && router.pathname.includes('elaboracao_planos') || router.pathname.includes('aprovacao_planos') && tipoFluxo == 'substituir') {
      GetInitiativesElaborations(planId).then((res: any) => {
        setData(res.data);
      });
    }
  }, [planId, router.pathname, tipoFluxo]);

  const { consultarOrganograma, salvarOrganograma, startOrganograma } = new Organograma(router.query.id as unknown as number);

  const { insertItemById, updateItemById, checkLayerExists, deleteItemById } = useHierarchyPreparationOfPlans();
  const { startRelacionamento, consultarRelacionamento, atualizarRelacionamento } = new RelacionamentoJsonService(router.query.id as unknown as number);

  const onSubmit: SubmitHandler<any> = async (data, event: any) => {
    const { iniciativas: iniciativasData } = data;
    const iniciativaId = iniciativas[page - 1]?.id;
    const iniciativaCode = completCode.find(item => item.code == 'iniciativas')

    let submittedData = iniciativasData;

    if (disabeSubmit) return;

    setDisableSubmit(true);

    if (event?.data?.length > 0) {
      submittedData = event.data;
    }

    try {
      for (const itemPrograms of submittedData) {
        const { tor, id, prazo, setor, orgao, ...rest } = itemPrograms;

        let eixo = '';
        let layerName = '';
        if (label === 'Macroação') {
          eixo = 'macroacaoId';
          layerName = 'macroacoes'
        }

        if (label === 'Meta') {
          eixo = 'metaId';
          layerName = 'metas'
        }

        let iniciativa = {
          ...rest,
          [eixo]: iniciativaId,
          eixo_tematico: eixos_tematicos[currentIndexEixo]?.id,
          territorio: encontrarCorrespondentes(itemPrograms.tor, filterData.territorio),
        };

        if (setor.length > 0) {
          iniciativa = { ...iniciativa, setor }
        }

        if (orgao.length > 0) {
          iniciativa = { ...iniciativa, orgao }
        }

        if (id) {
          await UpdateInitiativesElaborations(iniciativa, id);
        } else {
          const response = await InitiativesElaboration(iniciativa, router.query.id);

          const relacionamentoCheck = await consultarRelacionamento();
          const rootCheck = JSON.parse(JSON.parse(relacionamentoCheck));
          const checklayer = checkLayerExists({ root: rootCheck, layerName, targetId: iniciativaId });

          if (!checklayer) {
            for (const item of [iniciativa]) {
              const relacionamento = await consultarRelacionamento();
              const root = JSON.parse(JSON.parse(relacionamento));

              const newInsertItem = updateItemById({
                root,
                targetId: iniciativaId,
                item: { iniciativas: { itens: [] } },
                layerName,
              });

              startRelacionamento(JSON.stringify(newInsertItem));
              await atualizarRelacionamento(); // Aguarda o término da atualização
            }
          }

          for (const item of [iniciativa]) {
            const relacionamento = await consultarRelacionamento();
            const root = JSON.parse(JSON.parse(relacionamento));

            const newInsertItem = insertItemById({
              root,
              targetId: iniciativaId,
              item: { id: response?.data?.data, relacionamento: {} },
              layerName: 'iniciativas',
            });

            startRelacionamento(JSON.stringify(newInsertItem));
            await atualizarRelacionamento(); // Aguarda o término da atualização
          }
        }

        await delay(500);
      }

      toggleSuccessSnackbar();

      if (event.nativeEvent.submitter.name === 'next_step') {
        setNavigable(true)

        router.push('/dashboard/elaboracao_planos/' + router.query.id);

        const plan = itemsMenu[findingMenuItemKey(zustandSelectedCardItem)] as Record<string, any>;
        const planId = plan[selectedItemsMenu?.layer as string]
          .find((item: any) => item.id == selectedItemsMenu?.id).id + 1;
        setDefaultItemMenuId(planId);
      } else {
        setNavigable(false)
        const plan = itemsMenu[findingMenuItemKey(zustandSelectedCardItem)] as Record<string, any>;
        const planId = plan[selectedItemsMenu?.layer as string]
          .find((item: any) => item.id == selectedItemsMenu?.id).id;
        setDefaultItemMenuId(planId);

        router.push('/dashboard/elaboracao_planos/' + router.query.id)
        handleFetchProgressCodePlanId(router.query.id)
      }

      setTimeout(() => toggleSuccessSnackbar(), 3000)
    } catch (error) {
      toggleErrorSnackbar();
      setDisableSubmit(false);

    }

    setDisableSubmit(false);

    if (iniciativaCode != undefined) return;

    try {
      const response = await consultarOrganograma();
      const organograma = response.Organograma;

      const newItem = {
        "tooltip": "",
        "position": "",
        "manager": label,
        "name": "Iniciativa",
        "subordinates": [],
      };

      const newOrganograma = addItemToNodeOrganogram(organograma, label, newItem);
      startOrganograma(newOrganograma);
      salvarOrganograma();

    } catch {
      toggleErrorSnackbar('Organograma', 'Erro ao salvar o organograma.');
    }

  };

  const Fields = React.useCallback(() => {
    return (
      <>
        <Grid item xs={12} sx={{ ml: 2 }}>
          {fields.map((field: any, goalIndex: any) => {
            const randomId = Math.random();
            const tor = watchInitiatives(`iniciativas.${goalIndex}.tor`);
            const orgao = watchInitiatives(`iniciativas.${goalIndex}.orgao`);
            const setor = watchInitiatives(`iniciativas.${goalIndex}.setor`);
            const texto = watchInitiatives(`iniciativas.${goalIndex}.texto`);
            const territorio = watchInitiatives(`iniciativas.${goalIndex}.territorio`);
            const responsavel = watchInitiatives(`iniciativas.${goalIndex}.responsavel`);

            return (
              <Grid key={randomId} container spacing={2} sx={{ background: '#DFDFDF', borderRadius: '5px', mt: 2, padding: '0 1rem  0 0' }}>
                <Grid item xs={12}>
                  <input
                    type="hidden"
                    {...registerInitiatives(`iniciativas.${goalIndex}.id`, { value: field?.id })}
                    defaultValue={field?.id}
                  />
                </Grid>
                <Grid item xs={12} sx={{ mt: 2 }}>
                  <TextFieldElaboration
                    rows={3}
                    required={true}
                    multiline={true}
                    label="Iniciativa"
                    defaultValue={texto}
                    commentTittle={texto}
                    errors={errosInitiatives}
                    register={registerInitiatives}
                    placeholder="Digite a iniciativa"
                    name={`iniciativas.${goalIndex}.texto`}
                  />
                  <div className="error_wrapper">
                    <span>{getMessageErrorByNameRef(errosInitiatives, 'texto')}</span>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <SelectFieldElaboration
                    required={true}
                    label="Território"
                    commentTittle={texto}
                    defaultValue={territorio}
                    errors={errosInitiatives}
                    register={registerInitiatives}
                    placeholder="Selecione o território"
                    data={[
                      { value: 0, name: '-' },
                      { value: 1, name: 'AP' },
                      { value: 2, name: 'RP' },
                      { value: 3, name: 'RA' },
                      { value: 4, name: 'Bairros' }
                    ]}
                    name={`iniciativas.${goalIndex}.territorio`}
                    onClick={() => updateInitiatives(goalIndex, { ...field, tor: [] })}
                    setValue={(value) => setValueInitiatives(`iniciativas.${goalIndex}.territorio`, value)}
                  />
                  <div className="error_wrapper">
                    <span>{getMessageErrorByNameRef(errosInitiatives, 'territorio')}</span>
                  </div>
                </Grid>
                {territorio != 0 && (
                  <Grid item xs={12}>
                    <MultipleSelect
                      fullWidth={true}
                      value={Array.isArray(tor) ? tor : []}
                      setValue={(e) => {
                        const items = removeDuplicatesFromA(tor, e)
                        updateInitiatives(goalIndex, { ...field, tor: items })
                      }}
                      {...registerInitiatives(`iniciativas.${goalIndex}.tor`, { required: false })}
                      data={handleContentRelatedToTerritorySelected(filterData, territorio, tor)}
                    />
                  </Grid>
                )}
                <Grid item xs={3}>
                  <TextFieldElaboration
                    required={true}
                    errors={errosInitiatives}
                    commentTittle={texto}
                    label="Público-alvo"
                    register={registerInitiatives}
                    placeholder="Digite o público-alvo"
                    name={`iniciativas.${goalIndex}.publicoAlvo`}
                  />
                  <div className="error_wrapper">
                    <span>{getMessageErrorByNameRef(errosInitiatives, 'publicoAlvo')}</span>
                  </div>
                </Grid>
                <Grid item xs={3}>
                  <SelectFieldElaboration
                    label="Órgão"
                    required={false}
                    defaultValue={orgao}
                    commentTittle={texto}
                    placeholder="Selecione"
                    errors={errosInitiatives}
                    register={registerInitiatives}
                    name={`iniciativas.${goalIndex}.orgao`}
                    setValue={(value) => setValueInitiatives(`iniciativas.${goalIndex}.orgao`, value)}
                    data={filterData.orgaoResponsavel.map((item) => ({ value: item.id, name: item.sigla }))}
                  />
                  <div className="error_wrapper" style={{ marginTop: '-.2rem' }}>
                    <span>{getMessageErrorByNameRef(errosInitiatives, 'orgao')}</span>
                  </div>
                </Grid>
                <Grid item xs={3}>
                  <SelectFieldElaboration
                    label="Setor"
                    required={false}
                    defaultValue={setor}
                    commentTittle={texto}
                    placeholder="Selecione"
                    errors={errosInitiatives}
                    register={registerInitiatives}
                    name={`iniciativas.${goalIndex}.setor`}
                    data={filterSectors(filterData, orgao)}
                    setValue={(value) => setValueInitiatives(`iniciativas.${goalIndex}.setor`, value)}
                  />
                  <div className="error_wrapper" style={{ marginTop: '-.2rem' }}>
                    <span>{getMessageErrorByNameRef(errosInitiatives, 'setor')}</span>
                  </div>
                </Grid>
                <Grid item xs={3} sx={{ mt: -.3 }}>
                  <TextFieldElaboration
                    required={false}
                    label="Responsável"
                    errors={errosInitiatives}
                    defaultValue={responsavel}
                    commentTittle={texto}
                    register={registerInitiatives}
                    placeholder="Nome do responsável"
                    name={`iniciativas.${goalIndex}.responsavel`}
                  />
                  <div className="error_wrapper">
                    <span>{getMessageErrorByNameRef(errosInitiatives, 'responsavel')}</span>
                  </div>
                </Grid>
                {goalIndex != 0 && !preview && (
                  <Grid item xs={12} display='flex' sx={{ mb: 2, justifyContent: 'end', alignItems: 'center' }}>
                    <TrashIcon onClick={() => {
                      setOpenDeleteModal(true);
                      setDeleteIndex(goalIndex)
                      setDeleteId(field?.id)
                    }} />
                  </Grid>
                )}
                <DeleteElaborationDialog open={openDeleteModal} setOpen={(e) => setOpenDeleteModal(e)} onDelete={async () => {
                  removeInitiatives(deleteIndex);
                  if (deleteId != 0) {
                    const relacionamento = await consultarRelacionamento();
                    const root = JSON.parse(JSON.parse(relacionamento));
  
                    const newItem = deleteItemById({ root, layerName: 'iniciativas', targetId: deleteId });
  
                    startRelacionamento(JSON.stringify(newItem));
                    atualizarRelacionamento();

                    DeleteInitiativesElaborations(deleteId)
                    setDeleteId(0);
                  }
                  setOpenDeleteModal(false);
                }} />
              </Grid>
            )
          })}
        </Grid>
        <Grid item xs={12}>
          <div className="add_line_wrapper">
            <p onClick={() => appendInitiatives(defaultValue)}>
              {`+ Adicionar nova iniciativa`}
            </p>
          </div>
        </Grid>
      </>
    )
  }, [
    fields,
    filterData,
    openDeleteModal,
    errosInitiatives,
    appendInitiatives,
    updateInitiatives,
    removeInitiatives,
    controlInitiatives,
    registerInitiatives,
    zustandSelectedCardItem,
    handleContentRelatedToTerritorySelected,
  ]);

  const ThematicAxisBanner = React.useCallback(() => {
    return (
      <>
        <Grid item xs={12} className="goals_select_content_wrapper">
          <p>Eixo temático</p>
        </Grid>
        <Grid item xs={12}>
          <div className="goals_select_wrapper">
            <div className="goals_left_arrow" onClick={() => handleLeftClick()}>{"<"}</div>
            <div className="goals_select_content_wrapper">
              <p>{eixos_tematicos[currentIndexEixo]?.texto}</p>
              <span>Eixo temático {currentIndexEixo + 1} de {eixos_tematicos.length}</span>
            </div>
            <div className="goals_rigth_arrow" onClick={() => handleRightClick()}>{">"}</div>
          </div>
        </Grid>
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
          <div className="pagination_wrapper">
            <Pagination
              page={page}
              count={total}
              onChange={handleChange}
              hidePrevButton hideNextButton
              variant="outlined" shape="rounded"
            />
            <p>Página {total == 0 ? 0 : page} de {total}</p>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className="strategy_wrapper">
            <p>
              <b>{label}:</b>&nbsp;
              {iniciativas[page - 1]?.texto}
            </p>
          </div>
        </Grid>
      </>
    );
  }, [iniciativas, page, eixos_tematicos, handleChange, handleLeftClick, handleRightClick, label, currentIndexEixo]);

  const ReplaceButton = React.useCallback(() => {
    if (tipoFluxo != 'substituir') return null;

    return (
      <Grid item xs={4}>
        <button className="recicle_content_button" type='button' onClick={() => {
          if (data.length > 0) {
            setReutilizar(true);
          } else {
            setOpenDisclaimer(true);
          }
        }}>
          <p>Reutilizar conteúdo</p>
        </button>
      </Grid>
    );
  }, [tipoFluxo, data, tipoFluxo]);

  if (loading) {
    return <LoadingBuffer />
  }

  return (
    <form onSubmit={handleSubmitInitiatives(onSubmit)}>
      <Container>
        <Grid container spacing={1}>
          {ReplaceButton()}
          {ThematicAxisBanner()}
          {Fields()}
          <Grid item xs={12} sx={{ mt: 4 }}>
            <div style={{ height: '1px', background: '#B4B4B4' }}></div>
          </Grid>
          <Grid item xs={12}>
            <ActionButtonElaborations disable={disabeSubmit} isOptional="true" layer_indicator={false} />
          </Grid>
        </Grid>
        <ReutilizarElaborationDialog
          title="Macroações"
          reuseData={data}
          open={reutilizar}
          setOpen={() => setReutilizar(false)}
          setValue={(e) => {
            e.forEach((item: any, index: any) => {
              setValueInitiatives(`iniciativas.${index}`, transformInitiativesDataReuse(item))
            })
          }}
        />
        <DisclaimerReuseElaborationDialog open={openDisclaimer} setOpen={setOpenDisclaimer} />
      </Container>
    </form>
  )
}