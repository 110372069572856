import React from "react"
import * as Yup from 'yup';
import { isNumber } from "lodash";
import { Container } from './styled';
import { useRouter } from "next/router";
import { LoadingBuffer } from "../utils/Loading";
import { Grid, Pagination } from '@mui/material';
import { yupResolver } from "@hookform/resolvers/yup";
import { DeleteElaborationDialog } from "../../Modal/Delete";
import { useTipeOfFlow } from "@/services/zustand/tipo_fluxo";
import { clearIdToArray } from "@/utils/elaborationParseData";
import { TextFieldElaboration } from "../../Inputs/TextField";
import { ActionButtonElaborations } from "../../ActionButton";
import { useHierarchyPreparationOfPlans, usePreparationOfPlans } from "@/hooks/elaboracao_planos";
import { ElaborationService } from "@/services/endpoints/elaboracao";
import { ReutilizarElaborationDialog } from "../Vision/ReutilizarModal";
import { useSnackbarElaboration } from "@/hooks/useSnackbarElaboration";
import { SubmitHandler, useFieldArray, useForm } from "react-hook-form";
import { findingMenuItemKey } from "@/contexts/elaboracao_planos/constants";
import { DisclaimerReuseElaborationDialog } from "../Vision/disclaimerModal";
import { useElaborationCardItem } from "@/services/zustand/elaboration_card_item";
import { Organograma } from "@/utils/organograma/Organograma";
import { addItemToNodeOrganogram } from "@/utils/organograma/addItemToNode";
import { RelacionamentoJsonService } from "@/services/endpoints/hierarquia";

const defaultValue = {
  id: '',
  texto: '',
}

type InputsActivites = {
  atividades: any;
};

const SchemaActivites = Yup.object().shape({
  atividades: Yup.array().of(
    Yup.object().shape({
      texto: Yup.string().required('Campo obrigatório')
    })
  ).required()
});

export const ActivitiesForm: React.FC = () => {
  // const {
  //   page,
  //   label,
  //   setPage,
  //   projetos,
  //   watchActivites,
  //   errosActivites,
  //   appendActivites,
  //   eixos_tematicos,
  //   removeActivities,
  //   currentIndexEixo,
  //   registerActivites,
  //   setCurrentIndexEixo,
  //   setValueActivites,
  //   handleSubmitActivites,
  // } = useActivitiesForm();

  const {
    trigger,
    clearErrors,
    watch: watchActivites,
    control: controlActivites,
    register: registerActivites,
    setValue: setValueActivites,
    handleSubmit: handleSubmitActivites,
    formState: { errors: errosActivites } } = useForm<InputsActivites>({
      resolver: yupResolver(SchemaActivites),
      defaultValues: { atividades: [defaultValue] }
    });

  const { append: appendActivites, remove: removeActivities } = useFieldArray({
    control: controlActivites,
    name: "atividades"
  });

  const fields = watchActivites('atividades');

  const router = useRouter();
  const [page, setPage] = React.useState(1);
  const [label, setLabel] = React.useState('');
  const [data, setData] = React.useState<any[]>([]);
  const [deleteId, setDeleteId] = React.useState(0);
  const { tipo: tipoFluxo, planId } = useTipeOfFlow();
  const [loading, setLoading] = React.useState(false);
  const [deleteIndex, setDeleteIndex] = React.useState(0);
  const [projetos, setProjetos] = React.useState<any[]>([]);
  const [reutilizar, setReutilizar] = React.useState(false);
  const [loadingEixo, setLoadingEixo] = React.useState(false);
  const [preview, setPreview] = React.useState<boolean>(false);
  const [disabeSubmit, setDisableSubmit] = React.useState(false);
  const [openDisclaimer, setOpenDisclaimer] = React.useState(false);
  const [currentIndexEixo, setCurrentIndexEixo] = React.useState(0);
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const [eixos_tematicos, setEixosTematicos] = React.useState<any[]>([]);
  const { toggleErrorSnackbar, toggleSuccessSnackbar } = useSnackbarElaboration()
  const zustandSelectedCardItem = useElaborationCardItem(state => state.cardItem);
  const { ActivitiesElaboration, DeleteActivitiesElaborations, GetActivitiesElaborations, GetThematicAxisElaboration, GetProjectsEixoElaborations } = new ElaborationService();
  const { itemsMenu, selectedItemsMenu, completCode, handleFetchProgressCodePlanId, setDefaultItemMenuId, setNavigable } = usePreparationOfPlans();
  
  const total = projetos.length;

  const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));
  
  const handleChange = async (_: any, value: any) => {
    if (typeof _ == 'string') {
      setPage(value);
      return;
    }
    const isValid = await trigger();

    if (isValid) {
      handleSubmitActivites(onSubmit)({
        data: fields,
        nativeEvent: { submitter: { name: 'any' } }
      } as any);
    }
    setPage(value);
  };

  const handleLeftClick = () => {
    handleChange('', 1)
    handleExternalSubmit();
    setCurrentIndexEixo(currentIndexEixo === 0 ? eixos_tematicos.length - 1 : currentIndexEixo - 1);
  }

  const handleRightClick = () => {
    handleChange('', 1);
    handleExternalSubmit()
    setCurrentIndexEixo(currentIndexEixo === eixos_tematicos.length - 1 ? 0 : currentIndexEixo + 1);
  }

  function getMessageErrorByNameRef(json: any, title: any) {
    const foundObject = json?.atividades?.find((item: any) => item[title]);
    return foundObject ? foundObject[title].message : null;
  }

  const handleExternalSubmit = async () => {
    const isValid = await trigger();

    if (isValid) {
      handleSubmitActivites(onSubmit)({
        data: fields,
        nativeEvent: { submitter: { name: 'any' } }
      } as any);
    }
  };

  React.useEffect(() => {
    if (router.query.id && router.pathname.split('/').includes('elaboracao_planos') || router.pathname.split('/').includes('aprovacao_planos')) {
      setLoadingEixo(true)
      GetThematicAxisElaboration(router.query.id).then(res => {
        setEixosTematicos(res.data);
      }).finally(() => setLoadingEixo(false));
    }
  }, [router.query, router.pathname, completCode]);

  React.useEffect(() => {
    if (router.query.id && router.pathname.includes('elaboracao_planos') && eixos_tematicos[currentIndexEixo]?.id || router.pathname.includes('aprovacao_planos') && eixos_tematicos[currentIndexEixo]?.id) {
      setLoading(true)
      GetProjectsEixoElaborations(router.query.id, eixos_tematicos[currentIndexEixo]?.id).then(res => {
        setProjetos(res.data);
        setLabel('Projeto');
      }).finally(() => setLoading(true));
    }
  }, [router.query, router.pathname, eixos_tematicos, currentIndexEixo, completCode]);

  React.useEffect(() => {
    const projeto = projetos[page - 1] as any;
    clearErrors()
    if (projeto?.atividadesProjetos?.length > 0) {
      setValueActivites('atividades', projeto?.atividadesProjetos.map((item: any) => ({ id: item?.id, texto: item?.texto })))
    } else {
      setValueActivites('atividades', [defaultValue])
    }
  }, [projetos, page, completCode]);

  React.useEffect(() => {
    if (planId && router.pathname.split('/').includes('elaboracao_planos') || router.pathname.split('/').includes('aprovacao_planos') && tipoFluxo == 'substituir') {
      GetActivitiesElaborations(planId).then(res => {
        setData(res.data);
      });
    }
  }, [planId, tipoFluxo, router.pathname]);

  React.useEffect(() => {
    if (router.query.id && router.pathname.split('/').includes('aprovacao_planos')) {
      GetActivitiesElaborations(router.query.id).then(res => {
        setData(res.data);
      });
    }
  }, [router.query, router.pathname]);

  React.useEffect(() => {
    if (!router.isReady) return

    setPreview(router.asPath.includes('preview'));
  }, [router]);

  const { consultarOrganograma, salvarOrganograma, startOrganograma } = new Organograma(router.query.id as unknown as number);

  const { insertItemById, updateItemById, checkLayerExists, deleteItemById, findNewItemsWithId } = useHierarchyPreparationOfPlans();
  const { startRelacionamento, consultarRelacionamento, atualizarRelacionamento } = new RelacionamentoJsonService(router.query.id as unknown as number);

  const onSubmit: SubmitHandler<any> = async (data, event: any) => {
    const { atividades } = data;
    const atividadeCode = completCode.find(item => item.code == 'atividades')

    if (disabeSubmit) return;

    setDisableSubmit(true);

    let submittedData = atividades;

    if (event?.data?.length > 0) {
      submittedData = event.data;
    }

    try {
      const cleardIdData = clearIdToArray(submittedData);

      for (let index = 0; index < cleardIdData.length; index++) {
        const item = cleardIdData[index];
        const { id, ...rest } = item
        
        let parsedSummaryData = {
          ...rest,
          eixo_tematico: eixos_tematicos[currentIndexEixo]?.id,
          projetoId: projetos[page - 1]?.id,
        };

        if (isNumber(id)) {
          parsedSummaryData = {
            ...rest,
            id,
            eixo_tematico: eixos_tematicos[currentIndexEixo]?.id,
            projetoId: projetos[page - 1]?.id,
          };
        }

        const res = await ActivitiesElaboration(
          { itens: [parsedSummaryData] },
          router.query.id,
        );

        const response = res.data as any[];
        const newArray = findNewItemsWithId(parsedSummaryData, response);
        

        const relacionamentoCheck = await consultarRelacionamento();
        const rootCheck = JSON.parse(JSON.parse(relacionamentoCheck));
        const checklayer = checkLayerExists({ root: rootCheck, layerName: 'projetos', targetId: projetos[page - 1]?.id });
  
        if (!checklayer) {
          for (const item of newArray) {
            const relacionamento = await consultarRelacionamento();
            const root = JSON.parse(JSON.parse(relacionamento));
  
            const newInsertItem = updateItemById({
              root,
              targetId: projetos[page - 1]?.id,
              item: { atividades: { itens: [] } },
              layerName: 'projetos',
            });
  
            startRelacionamento(JSON.stringify(newInsertItem));
            await atualizarRelacionamento(); // Aguarda o término da atualização
          }
        }
  
        for (const item of newArray) {
          const relacionamento = await consultarRelacionamento();
          const root = JSON.parse(JSON.parse(relacionamento));
  
          const newInsertItem = insertItemById({
            root,
            targetId: projetos[page - 1]?.id,
            item: { id: item.id, relacionamento: {} },
            layerName: 'atividades',
          });
  
          startRelacionamento(JSON.stringify(newInsertItem));
          await atualizarRelacionamento(); // Aguarda o término da atualização
        }

        await delay(500);
        toggleSuccessSnackbar()
      }

      if (event.nativeEvent.submitter.name === 'next_step') {
        setNavigable(true)

        router.push('/dashboard/elaboracao_planos/' + router.query.id);

        const plan = itemsMenu[findingMenuItemKey(zustandSelectedCardItem)] as Record<string, any>;
        const planId = plan[selectedItemsMenu?.layer as string]
          .find((item: any) => item.id == selectedItemsMenu?.id).id + 1;
        setDefaultItemMenuId(planId);
      } else {
        setNavigable(false)
        const plan = itemsMenu[findingMenuItemKey(zustandSelectedCardItem)] as Record<string, any>;
        const planId = plan[selectedItemsMenu?.layer as string]
          .find((item: any) => item.id == selectedItemsMenu?.id).id;
        setDefaultItemMenuId(planId);

        router.push('/dashboard/elaboracao_planos/' + router.query.id)
        handleFetchProgressCodePlanId(router.query.id)
      }
      setTimeout(() => toggleSuccessSnackbar(), 3000);
      setDisableSubmit(false);
    } catch (error) {
      toggleErrorSnackbar();
      setDisableSubmit(false);
    }

    if (atividadeCode != undefined) return;

    try {
      const response = await consultarOrganograma();
      const organograma = response.Organograma;

      const newItem = {
        "tooltip": "",
        "position": "",
        "manager": label,
        "name": "Atividade",
        "subordinates": [],
      };

      const newOrganograma = addItemToNodeOrganogram(organograma, label, newItem);
      startOrganograma(newOrganograma);
      salvarOrganograma();

    } catch {
      toggleErrorSnackbar('Organograma', 'Erro ao salvar o organograma.');
    }
  };

  const ThematicAxisBanner = React.useCallback(() => {
    return (
      <>
        <Grid item xs={12} className="title_wrapper">
          <p>Eixo temático</p>
        </Grid>
        <Grid item xs={12}>
          <div className="goals_select_wrapper">
            <div className="goals_left_arrow" onClick={() => handleLeftClick()}>{"<"}</div>
            <div className="goals_select_content_wrapper">
              <p>{eixos_tematicos[currentIndexEixo]?.texto}</p>
              <span>Eixo temático {currentIndexEixo + 1} de {eixos_tematicos.length}</span>
            </div>
            <div className="goals_rigth_arrow" onClick={() => handleRightClick()}>{">"}</div>
          </div>
        </Grid>
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
          <div className="pagination_wrapper">
            <Pagination
              count={total}
              page={page}
              onChange={handleChange}
              hidePrevButton hideNextButton
              variant="outlined" shape="rounded"
            />
            <p>Página {page} de {total}</p>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className="strategy_wrapper">
            <p>
              <b>{label}:&nbsp;</b>
              {projetos[page - 1]?.texto}
            </p>
          </div>
        </Grid>
      </>
    );
  }, [projetos, page, eixos_tematicos, handleChange, handleLeftClick, handleRightClick, label, currentIndexEixo]);

  const Fields = React.useCallback(() => {
    return (
      <>
        <Grid item xs={12}>
          {fields.map((field: any, goalIndex: any) => {
            const randomId = Math.random();
            const texto = watchActivites(`atividades.${goalIndex}.texto`);

            return (
              <React.Fragment key={field?.id || randomId}>
                <input
                  type="hidden"
                  defaultValue={field?.id}
                  {...registerActivites(`atividades.${goalIndex}.id`, { value: field?.id })}
                />
                <TextFieldElaboration
                  rows={3}
                  required={true}
                  multiline={true}
                  defaultValue={texto}
                  commentTittle={texto}
                  errors={errosActivites}
                  register={registerActivites}
                  placeholder="Digite a atividade"
                  name={`atividades.${goalIndex}.texto`}
                  label={goalIndex == 0 ? 'Atividade' : ''}
                />
                <div className="error_wrapper">
                  <span>{getMessageErrorByNameRef(errosActivites, 'texto')}</span>
                </div>
                {goalIndex != 0 && (
                  <>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'end', gap: '8px' }}>
                      <div onClick={() => {
                        setOpenDeleteModal(true);
                        setDeleteIndex(goalIndex);
                        setDeleteId(field?.id);
                      }}
                        className="delete-icon"
                      >
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M6.755 2.12783C6.82416 1.98942 6.93048 1.87301 7.06206 1.79162C7.19364 1.71023 7.34528 1.66707 7.5 1.66699H12.5C12.6547 1.66707 12.8064 1.71023 12.9379 1.79162C13.0695 1.87301 13.1758 1.98942 13.245 2.12783L14.6817 5.00033H16.6667C16.8877 5.00033 17.0996 5.08812 17.2559 5.2444C17.4122 5.40068 17.5 5.61264 17.5 5.83366C17.5 6.05467 17.4122 6.26663 17.2559 6.42291C17.0996 6.57919 16.8877 6.66699 16.6667 6.66699H15.8333V15.8337C15.8333 16.4967 15.5699 17.1326 15.1011 17.6014C14.6323 18.0703 13.9964 18.3337 13.3333 18.3337H6.66667C6.00363 18.3337 5.36774 18.0703 4.8989 17.6014C4.43006 17.1326 4.16667 16.4967 4.16667 15.8337V6.66699H3.33333C3.11232 6.66699 2.90036 6.57919 2.74408 6.42291C2.5878 6.26663 2.5 6.05467 2.5 5.83366C2.5 5.61264 2.5878 5.40068 2.74408 5.2444C2.90036 5.08812 3.11232 5.00033 3.33333 5.00033H5.31833L6.755 2.12783ZM11.985 3.33366L12.8183 5.00033H7.18167L8.015 3.33366H11.985ZM9.16667 9.16699C9.16667 8.94598 9.07887 8.73402 8.92259 8.57774C8.76631 8.42146 8.55435 8.33366 8.33333 8.33366C8.11232 8.33366 7.90036 8.42146 7.74408 8.57774C7.5878 8.73402 7.5 8.94598 7.5 9.16699V14.167C7.5 14.388 7.5878 14.6 7.74408 14.7562C7.90036 14.9125 8.11232 15.0003 8.33333 15.0003C8.55435 15.0003 8.76631 14.9125 8.92259 14.7562C9.07887 14.6 9.16667 14.388 9.16667 14.167V9.16699ZM12.5 9.16699C12.5 8.94598 12.4122 8.73402 12.2559 8.57774C12.0996 8.42146 11.8877 8.33366 11.6667 8.33366C11.4457 8.33366 11.2337 8.42146 11.0774 8.57774C10.9211 8.73402 10.8333 8.94598 10.8333 9.16699V14.167C10.8333 14.388 10.9211 14.6 11.0774 14.7562C11.2337 14.9125 11.4457 15.0003 11.6667 15.0003C11.8877 15.0003 12.0996 14.9125 12.2559 14.7562C12.4122 14.6 12.5 14.388 12.5 14.167V9.16699Z" fill="#EC1F1F" />
                        </svg>
                      </div>
                    </div>
                    <DeleteElaborationDialog open={openDeleteModal} setOpen={(e) => setOpenDeleteModal(e)} onDelete={async () => {
                      removeActivities(deleteIndex);
                      if (deleteId != 0) {
                        const relacionamento = await consultarRelacionamento();
                        const root = JSON.parse(JSON.parse(relacionamento));
      
                        const newItem = deleteItemById({ root, layerName: 'atividades', targetId: deleteId });
      
                        startRelacionamento(JSON.stringify(newItem));
                        atualizarRelacionamento();

                        DeleteActivitiesElaborations(deleteId)
                        setDeleteId(0)
                      }
                      setOpenDeleteModal(false);
                    }} />
                  </>
                )}
              </React.Fragment>
            )

          })}
        </Grid>
        <Grid item xs={12}>
          <div className="add_line_wrapper">
            <p onClick={() => appendActivites(defaultValue)}>
              {`+ Adicionar nova atividade`}
            </p>
          </div>
        </Grid>
      </>
    );
  }, [
    fields,
    openDeleteModal,
    errosActivites,
    appendActivites,
    removeActivities,
    registerActivites,
    zustandSelectedCardItem,
  ])

  const ReplaceButton = React.useCallback(() => {
    if (tipoFluxo != 'substituir') return null;
    return (
      <Grid item xs={4}>
        <button className="recicle_content_button" type='button' onClick={() => {
          if (data.length > 0) {
            setReutilizar(true);
          } else {
            setOpenDisclaimer(true);
          }
        }}>
          <p>Reutilizar conteúdo</p>
        </button>
      </Grid>
    );
  }, [tipoFluxo, data, page, eixos_tematicos, handleChange, handleLeftClick, handleRightClick, label, openDisclaimer, reutilizar]);

  if (loadingEixo && loading) {
    return <LoadingBuffer />
  }

  return (
    <form onSubmit={handleSubmitActivites(onSubmit)}>
      <Container>
        <Grid container spacing={1}>
          {ReplaceButton()}
          <Grid item xs={12} sx={{ mb: 2 }}>
            <div style={{ height: '1px', background: '#B4B4B4' }}></div>
          </Grid>
          {ThematicAxisBanner()}
          {Fields()}
          <Grid item xs={12} sx={{ mt: 4 }}>
            <div style={{ height: '1px', background: '#B4B4B4' }}></div>
          </Grid>
          <Grid item xs={12}>
            <ActionButtonElaborations disable={disabeSubmit} layer_indicator={false} isOptional="true" />
          </Grid>
        </Grid>
        <ReutilizarElaborationDialog
          title="Programa"
          reuseData={data}
          open={reutilizar}
          setOpen={() => setReutilizar(false)}
          setValue={(e) => {
            e.forEach((item: any, index: any) => {
              setValueActivites(`atividades.${index}.texto`, item.texto);
            })
          }}
        />
        <DisclaimerReuseElaborationDialog open={openDisclaimer} setOpen={setOpenDisclaimer} />
      </Container>
    </form>
  )
}
